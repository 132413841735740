<template>
  <div>
    <!-- 搜索区域 -->
    <el-form label-width="130px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请选择学生类型">
            <el-select placeholder="请选择学生类型" v-model="search.type">
              <el-option label="全部" :value="''" />
              <el-option label="新生" :value="0" />
              <el-option label="老生" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请选择入学年级">
            <el-date-picker
              v-model="search.start"
              type="year"
              format="YYYY"
              value-format="YYYY"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入缴款人名称">
            <el-input v-model="search.payername"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="交款人身份证号码">
            <el-input v-model="search.payercardno"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请输入院系名称">
            <el-input v-model="search.collegeName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入专业名称">
            <el-input v-model="search.className"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请您选择层次">
            <el-select v-model="search.length" placeholder="请选择层次">
              <el-option
                v-for="item in hierarchicalData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入缴费名称">
            <el-input v-model="search.paySettingsName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!--  -->
        <el-col :span="6">
          <el-form-item label="请选择缴费项目">
            <el-select v-model="search.paySetting" placeholder="请选择缴费项目">
              <el-option
                v-for="item in paymentItemArr"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请选择支付状态">
            <el-select placeholder="请选择支付状态" v-model="search.payState">
              <el-option label="未支付" :value="0" />
              <el-option label="已支付" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请选择支付时间">
            <el-date-picker
              v-model="paymentTime"
              type="daterange"
              range-separator="到"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请选择退费状态">
            <el-select
              placeholder="请选择退费状态"
              v-model="search.refundState"
            >
              <el-option label="未退费" :value="0" />
              <el-option label="已退费" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请选择退费时间">
            <el-date-picker
              v-model="refundTime"
              type="daterange"
              range-separator="到"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>

        <el-col :span="1">
          <el-button type="primary" @click="onPayOrderList"> 搜索 </el-button>
        </el-col>

        <el-col :span="1">
          <el-button
            style="margin-left: 20px"
            type="success"
            @click="onPayTheFeesexport"
          >
            导出
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格区域 -->
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      stripe
      height="68vh"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column fixed type="selection" width="55" />
      <el-table-column fixed prop="payername" label="缴费人名称" width="100" />
      <el-table-column fixed prop="orderid" label="缴费编号" width="180" />
      <el-table-column fixed prop="amt" label="缴费金额" width="100" />
      <el-table-column prop="paySettingsName" label="缴费名称" width="180" />
      <el-table-column prop="collegeName" label="缴费人院系" width="180" />
      <el-table-column prop="className" label="缴费人专业" width="180" />
      <el-table-column prop="levelName" label="缴费人层次" width="180" />
      <el-table-column prop="trancode" label="交易码" width="180" />
      <el-table-column prop="trantype" label="交易种类" width="180" />
      <el-table-column prop="paytype" label="票据" width="180" />
      <el-table-column prop="xzqh" label="行政区划" width="180" />
      <el-table-column prop="type" label="学生类型" width="180">
        <template v-slot="{ row }">
          {{ row.type ? '老生缴费' : '新生缴费' }}
        </template>
      </el-table-column>
      <el-table-column label="证件号类型" width="180">
        <template v-slot="{ row }">
          {{ row.payercardtype == 10 ? '个人' : '其他' }}
        </template>
      </el-table-column>
      <el-table-column prop="payercardno" label="缴款人证件号码" width="180" />
      <el-table-column prop="paycode" label="缴款码" width="180" />
      <el-table-column prop="filldate" label="发起日期" width="180" />
      <el-table-column prop="payTime" label="缴款时间" width="180" />
      <el-table-column prop="upTime" label="退费时间" width="180" />
      <el-table-column prop="reason" label="退费原因" width="180" />
      <el-table-column
        fixed="right"
        prop="payState"
        label="缴款状态"
        width="100"
      >
        <template v-slot="{ row }">
          {{ row.payState ? '已支付' : '未支付' }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="退费状态" width="100">
        <template v-slot="{ row }">
          {{ row.refundState ? '已退费' : '未退费' }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template #default="{ row }">
          <el-button
            type="primary"
            size="small"
            @click="onEditBtn(row.orderid)"
          >
            明细
          </el-button>
          <el-button
            v-permission="['refundedBtn']"
            v-if="row.refundState === 0"
            type="primary"
            size="small"
            @click="refundBtn(row.orderid)"
          >
            设置为已退费
          </el-button>
          <el-button
            v-permission="['refundedBtn']"
            v-if="row.refundState === 1"
            style="border-color: red; background-color: red; color: white"
            type="primary"
            size="small"
          >
            已退费
          </el-button>

          <!-- <el-button
            v-permission="['refundedBtn']"
            v-else
            type="danger"
            size="small"
            @click="CancelRefundBtn(row.orderid)"
          >
            取消退费
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <!-- 明细 -->
    <detailed-pop-up-window
      v-model="detailVisible"
      :orderid="orderid"
    ></detailed-pop-up-window>
  </div>
  <renewal-wx-from
    v-model="detailVisibleWx"
    :orderid="id"
    :onPayOrderList="onPayOrderList"
  ></renewal-wx-from>
</template>

<script setup>
import { ref, watch } from 'vue'
import { listOfHierarchicalListingAPI } from '@/api/specialized'
// import { ElMessage, ElMessageBox } from 'element-plus'
import { paymentItemAPI } from '@/api/payFees'

import { payOrderList, payTheFeesexportAPI } from '@/api/finance'
import detailedPopUpWindow from './components/detailedPopUpWindow'
import renewalWxFrom from './components/renewalWxFrom'
/** 搜索 S */
const search = ref({
  page: 1,
  total: 0,
  limit: 10,
  type: 0, // 新生 0 / 老生 1
  payername: '', // 收款人姓名
  paySettingsName: '', // 缴费名称
  collegeName: '', // 院系名称
  className: '', // 专业名称
  paySetting: '', // 缴费项目
  length: '', // 层次
  payercardno: '', // 身份证
  payTimeStart: '', // 2022-07-07  // 缴费开始
  payTimeEnd: '', // 2022-08-15    // 缴费结束
  upTimeStart: '', // 2022-07-12  // 退费开始
  upTimeEnd: '', // 2022-07-15    // 退费结束
  payState: '', // 0  // 0 未支付 1 已支付
  refundState: '', // 0 // 0 未退费  1 已退费
  start: '', // 入学时间
  pay_type: '1'
})

// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}
onLayerPullDown()

// 缴费项目
// 缴费项目
const paymentItemArr = ref([])
const onPaymentItem = async () => {
  const data = await paymentItemAPI()
  paymentItemArr.value = data
}
onPaymentItem()

// 支付时间
const paymentTime = ref([])
watch(
  () => paymentTime,
  (val) => {
    if (!val.value) {
      search.value.payTimeStart = ''
      search.value.payTimeEnd = ''
    } else {
      search.value.payTimeStart = val.value[0]
      search.value.payTimeEnd = val.value[1]
    }
  },
  {
    deep: true
  }
)
// 退费时间
const refundTime = ref([])
watch(
  () => refundTime,
  (val) => {
    if (!val.value) {
      search.value.upTimeStart = ''
      search.value.upTimeEnd = ''
    } else {
      search.value.upTimeStart = val.value[0]
      search.value.upTimeEnd = val.value[1]
    }
  },
  {
    deep: true
  }
)

// 导出
const onPayTheFeesexport = async () => {
  const data = await payTheFeesexportAPI(search.value)
  window.location.href = `http://lnjsxy.college.yslts.com${data}`
}

/** 搜索 E */

/** 表格 S */
// 表格数据
const tableData = ref([])
const onPayOrderList = async () => {
  const { data, total } = await payOrderList(search.value)
  search.value.total = total
  tableData.value = data
}
onPayOrderList()
/** 表格 E */

// 明细
const detailVisible = ref(false)
const orderid = ref('')
const onEditBtn = (id) => {
  detailVisible.value = true
  orderid.value = id
}

// // 置为已退费
// const refundBtn = (id) => {
//   ElMessageBox.alert('是否将改订单置为退费', '退费', {
//     confirmButtonText: '确认',
//     callback: async (action) => {
//       if (action === 'cancel') {
//         return ElMessage.success('置为已退费已取消')
//       }
//       await wxPutAsARefundFeeAPI({ id })
//       ElMessage.success('置为已退费成功')
//       onPayOrderList()
//     }
//   })
// }
// 退费
const detailVisibleWx = ref(false)
const id = ref('')
const refundBtn = (orderid) => {
  console.log('orderid', orderid)
  console.log(detailVisibleWx.value)
  detailVisibleWx.value = true
  id.value = orderid
}

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onPayOrderList()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onPayOrderList()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
}
</style>
